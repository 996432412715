// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Swiper_swiper-wrapper__DMSpR {
  width: 100%;
  height: 100%;
}
.Swiper_swiper-wrapper__swiper-slide__y8iX5 {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Swiper_img__JaHOl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/swiper/style/Swiper.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAKA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAFF","sourcesContent":[".swiper-wrapper {\n  width: 100%;\n  height: 100%;\n\n  &__swiper-slide {\n    width: 100%;\n    height: 100%;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n.btn{\n\n }\n.img{\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper-wrapper": `Swiper_swiper-wrapper__DMSpR`,
	"swiper-wrapper__swiper-slide": `Swiper_swiper-wrapper__swiper-slide__y8iX5`,
	"img": `Swiper_img__JaHOl`
};
export default ___CSS_LOADER_EXPORT___;
