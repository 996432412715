// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chip_chipspan__1UxlC {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/chip/style/Chip.module.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ","sourcesContent":[".chip{\n  &span{\n    padding: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chipspan": `Chip_chipspan__1UxlC`
};
export default ___CSS_LOADER_EXPORT___;
