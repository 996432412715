// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkboxWithText_save-id__SuID1 {
  display: flex;
  align-items: center;
  display: center;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/checkbox/checkboxWithText/style/checkboxWithText.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;AACJ","sourcesContent":[".save-id{\n    display: flex;\n    align-items: center;\n    display: center;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"save-id": `checkboxWithText_save-id__SuID1`
};
export default ___CSS_LOADER_EXPORT___;
