// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: 'Pretendard Variable', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

/* 스크롤바 */
body::-webkit-scrollbar {

    width: 8px;
    background-color: #E8E8E8;
}
/* 스크롤바 위치 버튼 */
body::-webkit-scrollbar-thumb {

    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #585858;
}

code {
    font-family: 'Pretendard Variable', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Mui-root */
/*.MuiTypography-root, .MuiButtonBase-root{*/
/*    font-size: inherit;*/
/*    font-weight: inherit;*/
/*}*/
/*.MuiListItemButton-root {*/
/*    justify-content: stretch;*/
/*}*/
body, html{
    margin: 0;
    overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT;sEACkE;IAClE,mCAAmC;IACnC,kCAAkC;;AAEtC;;AAEA,SAAS;AACT;;IAEI,UAAU;IACV,yBAAyB;AAC7B;AACA,eAAe;AACf;;IAEI,kBAAkB;IAClB,oDAAoD;IACpD,yBAAyB;AAC7B;;AAEA;IACI;aACS;AACb;AACA,aAAa;AACb,4CAA4C;AAC5C,0BAA0B;AAC1B,4BAA4B;AAC5B,IAAI;AACJ,4BAA4B;AAC5B,gCAAgC;AAChC,IAAI;AACJ;IACI,SAAS;IACT,kBAAkB;AACtB","sourcesContent":["body {\n    margin: 0;\n    font-family: 'Pretendard Variable', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n\n}\n\n/* 스크롤바 */\nbody::-webkit-scrollbar {\n\n    width: 8px;\n    background-color: #E8E8E8;\n}\n/* 스크롤바 위치 버튼 */\nbody::-webkit-scrollbar-thumb {\n\n    border-radius: 4px;\n    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\n    background-color: #585858;\n}\n\ncode {\n    font-family: 'Pretendard Variable', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n/* Mui-root */\n/*.MuiTypography-root, .MuiButtonBase-root{*/\n/*    font-size: inherit;*/\n/*    font-weight: inherit;*/\n/*}*/\n/*.MuiListItemButton-root {*/\n/*    justify-content: stretch;*/\n/*}*/\nbody, html{\n    margin: 0;\n    overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
