export interface CsObject {
    [key: string]: FaqItem[];
}
export interface FaqItem {
    id: number;
    question: string;
    answer: string;
}

export const CsSuggest: CsObject[] = [
    {
        announce: [
            {
                id: 1,
                question: '공지사항 관련 이용 안내',
                answer: '몰라 걍 참고하세요'
            },
            {
                id: 2,
                question: '개인정보를 요구 스팸 주의',
                answer: '이제 당신의 계정은 제껍니다'
            },
            {
                id: 3,
                question: '모델 ASTS-1234 제품 하자 안내',
                answer: '교환 안해주니까 소용없어요'
            },
            {
                id: 4,
                question: '운영정책 위반하지 마시길 바랍니다',
                answer: '하지 말라고'
            },
            {
                id: 5,
                question: '해당 옵션을 클릭하지 말아주세요',
                answer: '클릭하지 말라고'
            },
        ],
        delivery: [
            {
                id: 6,
                question: '배송은 얼마나 걸리나요?',
                answer: '배송 소요 기간은 지역 및 배송 옵션에 따라 다르며, 주문 후 평균 3-5 영업일이 소요됩니다.'
            },
            {
                id: 7,
                question: '배송비는 어떻게 계산되나요?',
                answer: '배송비는 주문한 상품의 무게와 크기, 배송지에 따라 계산되며, 주문서 작성 시 자동으로 계산됩니다.'
            },
            {
                id: 8,
                question: '배송 추적은 어떻게 할 수 있나요?',
                answer: '주문 완료 후 배송 추적 번호가 제공되며, 해당 번호를 사용하여 온라인에서 실시간으로 배송 상태를 확인할 수 있습니다.'
            },
            {
                id: 9,
                question: '배송 주소를 변경할 수 있나요?',
                answer: '주문 완료 후 배송 주소를 변경하려면 빠르게 고객 서비스에 문의해 주세요. 변경이 가능한 경우 도움을 드리겠습니다.'
            },
            {
                id: 10,
                question: '배송 중 상품이 손상되었을 경우 어떻게 해야 하나요?',
                answer: '배송 중 손상된 상품이 도착하면 즉시 고객 서비스에 연락하여 사진을 제공해 주세요. 우리는 신속하게 대처하여 도움을 드릴 것입니다.'
            },
            {
                id: 11,
                question: '배송이 누락된 경우 어떻게 조치하나요?',
                answer: '배송 중 누락된 상품이 있다면 즉시 고객 서비스에 알려주시면, 빠르게 조치하여 빠른 배송을 도와드리겠습니다.'
            },
            {
                id: 12,
                question: '배송이 지연되는 경우 어떻게 확인할 수 있나요?',
                answer: '배송이 예상보다 더 오래 걸리는 경우에는 배송 추적 번호를 사용하여 배송 업체에 직접 문의하거나 고객 서비스에 문의해 주세요.'
            },
            {
                id: 13,
                question: '배송 시간을 지정할 수 있나요?',
                answer: '일부 지역에서는 배송 시간을 지정할 수 있습니다. 주문할 때 배송 시간 옵션을 확인하고 선택할 수 있습니다.'
            },
            {
                id: 14,
                question: '해외 배송이 가능한가요?',
                answer: '해외 배송은 가능하나 추가 배송비가 부과될 수 있습니다. 정확한 배송비와 시간은 주문 시 확인해 주세요.'
            },
            {
                id: 15,
                question: '주문 취소 후에도 배송비를 환불받을 수 있나요?',
                answer: '주문 취소 시 배송비는 상품이 아직 출고되지 않았을 경우에만 환불됩니다. 이미 출고된 경우에는 환불이 어려울 수 있습니다.'
            }
        ],
        return:[
            {
                id: 16,
                question: '상품을 반품하려면 어떻게 해야 하나요?',
                answer: '상품 반품은 마이 페이지에서 진행할 수 있습니다. 반품 절차에 따라 필요한 정보를 제공하고, 상품 회수 일정을 확인하세요.'
            },
            {
                id: 17,
                question: '반품 배송비는 어떻게 되나요?',
                answer: '반품 배송비는 고객 부담이며, 반품 접수 시에 자세한 안내를 받아볼 수 있습니다. 일부 특별한 경우에는 무료 반품이 가능할 수 있습니다.'
            },
            {
                id: 18,
                question: '상품을 반품한 후 환불 기간은 얼마나 걸리나요?',
                answer: '상품이 정상적으로 반품되면 환불 처리가 시작됩니다. 환불 소요 기간은 일반적으로 반품 확인 후 7-10 영업일이 소요됩니다.'
            },
            {
                id: 19,
                question: '반품이 거절될 경우 어떻게 되나요?',
                answer: '반품 신청이 거절되면 해당 이유와 함께 안내가 제공됩니다. 다른 조치를 취하기 전에 고객 서비스에 문의하여 자세한 도움을 받을 수 있습니다.'
            },
            {
                id: 20,
                question: '반품 상품은 어떤 상태여야 하나요?',
                answer: '반품 상품은 사용 흔적이 없어야 하며, 원래 포장이나 라벨이 손상되지 않도록 주의해 주세요.'
            },
            {
                id: 21,
                question: '반품 상품 포장 시 주의사항은 무엇인가요?',
                answer: '상품을 반품할 때는 원래 포장을 사용하고, 포장 시 추가 보호 재료를 사용하여 상품이 손상되지 않도록 주의하세요.'
            },
            {
                id: 22,
                question: '반품 신청 후 주문을 취소할 수 있나요?',
                answer: '반품 신청 후 주문을 취소하려면 빠르게 고객 서비스에 문의하여 상황을 확인하세요.'
            },
            {
                id: 23,
                question: '상품 반품 접수가 완료되면 어떻게 확인할 수 있나요?',
                answer: '반품 접수가 완료되면 이메일이나 마이 페이지를 통해 반품 상태를 확인할 수 있습니다.'
            },
            {
                id: 24,
                question: '반품 상품 회수 일정은 어떻게 되나요?',
                answer: '반품 신청이 완료되면 상품 회수 일정이 제공되며, 해당 날짜에 상품을 회수해 주시면 됩니다.'
            },
            {
                id: 25,
                question: '교환과 반품은 어떻게 다른가요?',
                answer: '교환은 상품 교체를 의미하고, 반품은 상품을 반환하고 환불을 받는 것을 의미합니다. 상황에 따라 선택하세요.'
            },
        ],
        refund:[
            {
                id: 26,
                question: '주문을 취소하려면 어떻게 해야 하나요?',
                answer: '주문 취소는 마이 페이지에서 간단하게 진행할 수 있습니다. 주문 상세 페이지에서 취소 옵션을 선택하세요.'
            },
            {
                id: 27,
                question: '주문 취소 가능 기간은 어떻게 되나요?',
                answer: '주문 취소 가능 기간은 주문 완료 후 일정 기간까지이며, 마이 페이지에서 주문 상세 정보를 확인하여 취소 가능 여부를 확인할 수 있습니다.'
            },
            {
                id: 28,
                question: '취소한 주문은 어떻게 환불되나요?',
                answer: '주문 취소가 완료되면 해당 주문에 대한 결제 금액이 원래 결제 수단으로 자동 환불됩니다. 환불 소요 기간은 7-10 영업일 정도입니다.'
            },
            {
                id: 29,
                question: '취소 후에도 배송비는 환불되나요?',
                answer: '주문 취소 시에는 배송비 또한 함께 환불됩니다. 단, 이미 상품이 발송된 경우에는 배송비 환불이 어려울 수 있습니다.'
            },
            {
                id: 30,
                question: '주문 취소 후에도 포인트는 지급되나요?',
                answer: '주문 취소 시에는 해당 주문에 대한 적립된 포인트가 자동으로 회수되므로, 이에 대해 걱정하지 않으셔도 됩니다.'
            },
            {
                id: 31,
                question: '주문 취소가 완료되면 어떻게 확인할 수 있나요?',
                answer: '주문 취소가 성공적으로 완료되면 이메일 또는 마이 페이지에서 해당 주문의 취소 상태를 확인할 수 있습니다.'
            },
            {
                id: 32,
                question: '주문 취소가 불가능한 경우는 어떤 경우가 있나요?',
                answer: '주문 취소가 불가능한 경우, 이미 상품이 출고된 경우 또는 특정 기간이 지난 경우 등이 해당될 수 있습니다. 자세한 사항은 마이 페이지에서 확인하세요.'
            },
            {
                id: 33,
                question: '취소한 주문을 복구할 수 있나요?',
                answer: '취소한 주문을 복구하려면 빠르게 고객 서비스에 문의하여 가능 여부를 확인하고 추가 지원을 받아보세요.'
            },
            {
                id: 34,
                question: '취소 후에도 주문 상태를 확인할 수 있나요?',
                answer: '취소 후에도 해당 주문의 상태를 마이 페이지에서 확인할 수 있습니다. 취소가 처리되면 주문 상세 페이지에서 자세한 정보를 확인하세요.'
            },
            {
                id: 35,
                question: '취소할 때 주의할 사항은 무엇인가요?',
                answer: '주문 취소 시에는 반드시 주문 상세 정보를 정확히 확인하고, 취소할 상품 및 옵션을 정확히 선택하여 처리하세요.'
            },
        ],
        account: [
            {
                id: 36,
                question: '계정을 생성하려면 어떻게 해야 하나요?',
                answer: '계정 생성은 홈페이지 상단의 "가입" 또는 "회원가입" 버튼을 클릭하여 필요한 정보를 입력하고, 가입 양식을 제출하면 됩니다.'
            },
            {
                id: 37,
                question: '비밀번호를 분실했을 때 어떻게 찾을 수 있나요?',
                answer: '로그인 페이지에서 "비밀번호 찾기" 옵션을 선택하고, 등록된 이메일 주소를 입력하여 비밀번호를 재설정할 수 있는 안내를 받을 수 있습니다.'
            },
            {
                id: 38,
                question: '계정 정보를 수정하려면 어디에서 할 수 있나요?',
                answer: '마이 페이지에서 "계정 정보 수정" 또는 "개인 정보 수정" 옵션을 선택하여 필요한 정보를 수정할 수 있습니다.'
            },
            {
                id: 39,
                question: '계정을 삭제하려면 어떻게 해야 하나요?',
                answer: '계정 삭제는 보안 이유로 고객 서비스를 통해 진행되므로, 빠르게 연락하여 삭제 절차에 대한 안내를 받아보세요.'
            },
            {
                id: 40,
                question: '이메일 주소를 변경하려면 어떻게 해야 하나요?',
                answer: '이메일 주소 변경은 마이 페이지에서 "이메일 주소 변경" 옵션을 선택하여 새로운 이메일 주소를 등록하면 됩니다.'
            },
            {
                id: 41,
                question: '계정 보안을 강화하려면 어떤 방법이 있나요?',
                answer: '계정 보안을 강화하려면 강력한 암호를 사용하고, 두 단계 인증(2FA)을 활성화하는 등의 보안 조치를 취할 수 있습니다.'
            },
            {
                id: 42,
                question: '회원 등급 혜택은 어떻게 확인하나요?',
                answer: '마이 페이지에서 회원 등급을 확인할 수 있으며, 등급별 혜택은 홈페이지 또는 이메일 통지를 통해 안내됩니다.'
            },
            {
                id: 43,
                question: '계정 정보 유출 시 어떻게 대처해야 하나요?',
                answer: '계정 정보 유출이 의심되면 즉시 고객 서비스에 연락하여 상황을 보고하고, 비밀번호를 변경하는 등의 조치를 취해야 합니다.'
            },
            {
                id: 44,
                question: '다른 사람과 계정을 공유할 수 있나요?',
                answer: '계정 공유는 보안 및 개인 정보 보호를 위해 권장되지 않습니다. 개별적인 계정을 유지하여 사용하는 것이 안전합니다.'
            },
            {
                id: 45,
                question: '계정에 로그인이 안 될 때 어떻게 해야 하나요?',
                answer: '로그인에 문제가 발생하면 우선 올바른 아이디와 비밀번호를 확인하고, 여전히 문제가 해결되지 않으면 고객 서비스에 문의하세요.'
            },
        ]
    }
]