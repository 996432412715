import { ItemInterface } from '@util/test/interface/Item';

export const itemResponse: ItemInterface[] = [
    {
        publicId: 1,
        categoryPublicId: 1,
        title: '[24년5월입고] 원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        content: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        summary: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://m.comics-art.co.kr/web/product/big/202403/9cd6009b2de8a7e65396a810758d6a03.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 2,
        categoryPublicId: 2,
        title: '[24년6월입고] 이누야샤 후류 trio try it 피규어 셋쇼마루',
        content: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        summary: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/big/202403/6d066cb4fbd286efa92256310e5dfecf.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 3,
        categoryPublicId: 3,
        title: '[24년7월입고] 미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        content: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        summary: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/big/202403/fcd601b0f1f0d3fe2b586a9a9837e379.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 4,
        categoryPublicId: 4,
        title: '[24년8월입고] 새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        content: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        summary: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/big/202403/d6a081e951dff67f0a102c5e5fced15d.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 5,
        categoryPublicId: 5,
        title: '[입고완료] 헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        content: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        summary: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/medium/202403/1692a4c36e941799d7a920819dcc12df.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 6,
        categoryPublicId: 6,
        title: '[24년5월입고] 원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        content: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        summary: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/medium/202310/97e5ee5fe4d83f06615e0d7752076211.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 7,
        categoryPublicId: 7,
        title: '[24년6월입고] 이누야샤 후류 trio try it 피규어 셋쇼마루',
        content: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        summary: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/medium/202310/cae0fb55cc65c3cbafbfba6fd5e29906.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 8,
        categoryPublicId: 8,
        title: '[24년7월입고] 미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        content: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        summary: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/medium/202311/c3ed4c78a15f5d599fc40d5db20bc6bd.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 9,
        categoryPublicId: 9,
        title: '[24년8월입고] 새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        content: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        summary: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://m.comics-art.co.kr/web/product/big/202404/9c6cfedac1a602da7203b8a1227268d8.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 10,
        categoryPublicId: 10,
        title: '[입고완료] 헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        content: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        summary: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/medium/202404/6d1d83ebec36f767863548d5c5a65798.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 11,
        categoryPublicId: 11,
        title: '[입고완료] 헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        content: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        summary: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://m.comics-art.co.kr/web/product/big/202404/fd5d011333ba9708d55d0c89706c5efc.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 12,
        categoryPublicId: 12,
        title: '[24년5월입고] 원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        content: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        summary: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/medium/202404/e7737380d172d4887326e39e98f620a1.png'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 13,
        categoryPublicId: 13,
        title: '[24년6월입고] 이누야샤 후류 trio try it 피규어 셋쇼마루',
        content: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        summary: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://m.comics-art.co.kr/web/product/big/202404/f7d64305a3b1e56eb325a0c7d15d141d.png'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 14,
        categoryPublicId: 14,
        title: '[24년7월입고] 미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        content: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        summary: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://m.comics-art.co.kr/web/product/big/202404/40ecb63c964cafd6c485097a4ad5c0f7.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 15,
        categoryPublicId: 15,
        title: '[24년8월입고] 새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        content: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        summary: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        price: 25000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: ['https://comics-art.co.kr/web/product/medium/202404/e696390dc88da6b4a142658263104076.jpg'],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
];

export const swiperResponse: ItemInterface[] = [
    {
        publicId: 1,
        categoryPublicId: 1,
        title: '[24년5월입고] 원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        content: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        summary: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/c4edf6cb-05d7-4f4d-9980-305c1d3fccdb.jpg?Expires=1708677923&Signature=hdQSd-sv3B7Qx-NMvR7VNIj8a4SF~iyg-Yk9FDt1ivMK8lM62iDGlbPhzW~eDhAZeC1AJip0g7iNKbXzEE4~JDiXjzNjNMddYr9WsLB7T3HGOYwz5ECikGLXW6ajnwFo1PF-6bEnGzbsgRu0V7074KGHkDbn-i77la-4nX8ER7cyuUDPlSC58nkM1MQuSuUqhtzNEWX6EVPlhDwrEShwVX4V1L2C8PNhRjPmPEPmze1xU1721Woj9bKoWHZjMjxqxebjcLVq72yn2flqqiaybooPTH4ief2we7RyzNP448LaKfaAz5zxonf0zspSfJqA8w1rf1n0eukBEnSBIn9BnQ__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 2,
        categoryPublicId: 2,
        title: '[24년6월입고] 이누야샤 후류 trio try it 피규어 셋쇼마루',
        content: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        summary: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/00f16fe1-4ef7-4589-898e-ee14e7fddfc8.jpg?Expires=1708677923&Signature=TIRsY6mAeAAwv8O3YKb5iJ2-cX2jZ8Aytm-F7xutoJvmls97AL2uu1wD4MaEZcQVusZvwEUT2f5TXT8fTwhZRaR-XsI1SwnxTf8LpZansTbJwahEk9bzErhHumlE6WgpbXktykpzCD49h6XYSigFji-NLjMTEl~zIbBX8eqxdwdyJD2mJK68oFrQztQtiMD7lrGcef8AJP3v3IK788Y3BJD5o4hwEAh5a-2vtX1z2BBfiOY28eU4THjcYUO-s1bvHemS8zhkX1guWEhTr6WZ7KwmNxOT43hcy3aw0DLyn1tDoNnd00DnNL~ascXjvWpgYt7WSg8B8WATI6Tu96n7RQ__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 3,
        categoryPublicId: 3,
        title: '[24년7월입고] 미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        content: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        summary: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/carousel_hero_hitmanreborn_w.jpg?Expires=1708677924&Signature=Ia5Vw~Tkxeotc-Yh8rhfHSKYRMMm~V-myHNXb7rQAMGL3eRbxbzZdgshWJrB2jmZko77ARg9qG17~u~4Q0td16Yhgr4vkmHyDD394DZyrWFxqz3gua6kodRk91gD~7As~h5pO42SKoTEcJDLQQI5Bi1jPZ9heTS~ymz2zHBlaHDEqdKHNmFox7X8o90ZI-5ubBEqGANNMiQQ5Mkw-vA8OhmIzWs7wH8zo1r5KJY3GuKceYemcaIqZ7RU8PIl7egD-XkWmccepFmSn0R3FNOBX0ED1WsP6H0a5Z3tJtPqMZhWmo-Js72dPZvMneeypZaVLud9dzAl~zfTM45UT157Bg__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 4,
        categoryPublicId: 4,
        title: '[24년8월입고] 새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        content: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        summary: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/carousel_hero_azumangadaioh_w.jpg?Expires=1708677923&Signature=VxEk6LLHGVRwg2L5qxa-DbvDbwteeIZu~pJ1i~oviUV4CjlUvkmnPmOFzc4v9byr~F5aXzkQ0m4KPhyvgp7HaChd7RMRH3RSh3s7ZSSv-3wqzuJkYYJFKh~zpVPa2eYdzCBfRnP-VjFyIcSneNv2WpcoROfMY7sqBZ53yOBAGJW-b6PrYPgwKZvwFMgy0itspDHn5lBEt9Irb9aJ~cEr4RICTo-0uyYvEENfqqJRGUnWItBzHdIkkK5Pe7evcol73HxP3LB6I3UuNtW5-AQukizGyFL2VHVX~iNiqMvAnecFu-I5aFZKNECgZx-9Exg41HNaGqwRpjRdAVPv4-CfNQ__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 5,
        categoryPublicId: 5,
        title: '[입고완료] 헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        content: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        summary: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/2f93f0f7-85c3-4d54-bfe7-7f7b52ec048c.jpg?Expires=1708677923&Signature=VoI6yW4dHwD39ngWJ57llrUF2yJGR3Abe4QXPsr0wXdVXk3~BFG9o1jU75WqyUFqntMPLaxn5lKXx8LT794btQSEkSn0I281umtSrdLjcftJva6P7IEqBSfH9AKD~kgbzsmNSQj3iG8-Q6ckPUEftiRlNtDa666lB56KMZwQWs6NpZ4jP1Vu1IZVkM8--OYUOSFG4lsbFA-4rqxLwjEyHCp4QGl7QexXz4A8cIidJE7qyad8fMWhw8evmROXYya5VOvarWJXTA7Sng~L3rKqg2LEWlQXfmsV9QzO2ZVaBH~~-km-BSSBUyI8sVgo3vuY0hGssJdF75mKIgkOejX0NA__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 11,
        categoryPublicId: 11,
        title: '[입고완료] 헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        content: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        summary: '헌터x헌터 굿스마일컴퍼니 넨도로이드 No.1444 히소카',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/45195d1d-40f9-435b-b31e-09df4ed99d4f.jpg?Expires=1711618242&Signature=fD7j-ZynT01QwPXGA5Xr2io4HWBcHB1im~SeWsjYHsv5iyRVrj6YRi4xgi9yRPvflmtuVEai26PtI5xdIkxzAOBBBA69aJMnjg5joPG6Rqyp7ciVkRd~cdvxe7TdbZ74X4dcaBCxG2SDW6MgWkV1SRlrVIGubfmz2hiW0uf3-JcDmeJqsCPl7~LKihKzYvLQEwQe2io~R-U8iTtQXyWjx-b5AgvRyiA-SI-yrxLHDWKRK8ElbSiUjDwO900oMzrDCw5FvJ-7xwSiLYSVjDTIi~tlDn8b5KsCJI9YoMzZlLOY82s~ICDa36hf~EcUe7E~iN8AYWwYeBn8nb~uLbMCpw__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 12,
        categoryPublicId: 12,
        title: '[24년5월입고] 원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        content: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        summary: '원피스 반다이 S.H.Figuarts 몽키.D.루피 오니가시마 습격ver (재판)',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/6cfe1d77-0cf3-4ac9-987c-4667db205e75.jpg?Expires=1711618241&Signature=AsZW9Dz9p8lOJUGfM-ZomfJbBXAjlE~BCxEBBhVOT2p82vUSJSAllZpq0xvHqHtslzXKxMQcJxJK6phsIxb-aFCvJoj5Kwq6Q7el41imm1T-KV3dBti9-i8tYiynxkleOTe7b6OrCviJqNTzorUeq3CbICwuZAgvZgtg3p0THQlkQM7BGbX1VJNPndvDXqL1oIeD~DyLGCi1Ec60x5MHm189~EGVGzRDZ8BOBnKcIGQMKi~jrLzkQtmCOwM0kMhTIED7a~OIRu71aa0pA6XkpryZxtyf~YzXgbprRh6mICfTyokmKwc-78OMiFJ86gbn9EyWHmE7vES9-BzXRROSKA__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 13,
        categoryPublicId: 13,
        title: '[24년6월입고] 이누야샤 후류 trio try it 피규어 셋쇼마루',
        content: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        summary: '이누야샤 후류 trio try it 피규어 셋쇼마루',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/22992b76-4eea-4942-b642-efc1bfd24aad.jpg?Expires=1711618242&Signature=PL4qf1MteMPKmpd6bfkUO3O4nGJcZAtrX~d6JuBnQeBjzbFPw8jQPMTNhUenWQTLt10LJooAkKSJogdpnswfOZGAyiAz9tTcuwqtm8VACl1ywP5RnYYwaRle5NvB9EMOfqchAhRdsMfEdCTGFFm6GOBLgiXVkXAGlA0WmrPcOW4HPWxwkt2mhmoLreVjEd3TkAcAXvSt0XLKOJYKbLbwU2CC60r4A8T24esgBoP-AKIDF1XrU~4yffVZK2Gy5TObjSqfe6-oNu8ikQJAzo7sYyW9mkg1BC2GpXJVfCdEiOQDTRUVWzDKHtX0CsWjGuZmuQejuIovJCevP2Z-jMhOxQ__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 14,
        categoryPublicId: 14,
        title: '[24년7월입고] 미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        content: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        summary: '미니언즈 굿스마일컴퍼니 넨도로이드 No.2187 밥',
        price: 50000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/carousel_hero_onnanosono_w.jpg?Expires=1711618242&Signature=NKfccungpRojPQ6njRYXy1pkvfOhi6YJ~czgVyHvQn-2UiXv3bPIXCuXoTV3bVFfjG7K9yLitn5OxjGWq0X49tsAkS7TnTd7QLmpjVctWPDVCGiHrO-gadxOKTd8ICSC~nK-T6aRAyWZaBYaihdgqB4Vhxi3ZBOsFhKraIORIfRZVUo42tb8jf1BfnVVTnHqR~5nSnO1dsrhiDFCyzukoLTq5ToPs3RGySfLaL-F6b5-LOtfnmvo5IdTWr8Jliz2a9Ru2-qa-jjoI17erE27hrIpOG1mf~YMIXInByTj2YRoa2Hlh7ppSjxH8TV6MMgBwPkJfsjtGoUAO4WZlOqprQ__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
    {
        publicId: 15,
        categoryPublicId: 15,
        title: '[24년8월입고] 새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        content: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        summary: '새로운 상사는 귀여운 허당 오렌지루즈 넨도로이드 No.2386 시로사키 유세이',
        price: 25000,
        sellCnt: 130,
        wishCnt: 120,
        stockCnt: 150,
        clickCnt: 180,
        avgReview: 4.3,
        reviewCnt: 15,
        qnaCnt: 12,
        status: false,
        sellerId: '판매완료',
        freeDelivery: false,
        imageUrls: [
            'https://image.laftel.net/carousel/6df65659-27bc-45df-aad1-98cbc1142c16.jpg?Expires=1711618242&Signature=BoMTOCcgAUAWjqTgx3ORKAePCbhtvouJ8xoPcV4EUmnBqQWX-68LOLJUFFE0Bt33ayXGSHFhzj~5flv~jPbU-mR69EA4SwUlljCdRmn8O3mA9hsBlF-ma3R927DMPkT6buMxRb2bU3X12weSi2nPWorska0rZnB8pKVL2ZR3BbEAi2DbfE8oa1WE2VkY5t5rV5JywgyIKmoPHKkdPj-QkU0nz81qDezeYreQfxEdG00qnCul3EPTIty0LyzBbPXh6XwlVoSP2wW0~bSjvp6wijQsBcvNyPRZWTuhnM4y8nAMFhfNnAKCvR95FNuzD2r62Um2DFtVxT3grgwELKy6IQ__&Key-Pair-Id=APKAJMMAKL567BYWKQ5A',
        ],
        option: ['Primary SelectBox', 'Secondary SelectBox'],
        productNumber: 'productNumberTest001',
        deadline: '2024-08-30T10:15:30Z',
        originalWork: '원피스',
        material: 'ABS / PVC ',
        size: '123cm',
        weight: '123g',
        shippingCost: 3000,
    },
];
