// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* 헤더 높이 전역변수 정하기 */
.AsideNav_nav__U2GUK {
  box-sizing: border-box;
}
.AsideNav_nav__logo__07EvP {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  height: 64px;
  margin: 0;
}
.AsideNav_nav__logo__07EvP span {
  width: 100%;
  box-sizing: border-box;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/admin/asideNav/styles/AsideNav.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,mBAAA;AACA;EACE,sBAAA;AAEF;AADE;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;AAGJ;AAFI;EACE,WAAA;EACA,sBAAA;EACA,YAAA;AAIN","sourcesContent":["/* 헤더 높이 전역변수 정하기 */\n.nav{\n  box-sizing: border-box;\n  &__logo{\n    display: flex;\n    align-items: center;\n    box-sizing: border-box;\n    text-align: center;\n    height: 64px;\n    margin: 0;\n    span{\n      width: 100%;\n      box-sizing: border-box;\n      color: white;\n    }\n  }\n  &__ul{\n\n  }\n}\n// 애니메이션\n.hover{\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `AsideNav_nav__U2GUK`,
	"nav__logo": `AsideNav_nav__logo__07EvP`
};
export default ___CSS_LOADER_EXPORT___;
