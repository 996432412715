import { ReviewResponse as Review } from '@interface/review/Review';

export const ReviewResponse: Review[] = [
    {
        reviewId: 'reviewId1',
        title: '[입고완료] 청춘 돼지는 바니걸 선배의 꿈을 꾸지 않는다 타이토 Coreful 피규어 아즈사가와 카에데',
        content: '아즈사가와 카에데 피규어',
        parentPublicId: '',
        score: 4.15,
        status: false,
        userId: 'userId1',
        itemId: 'itemId1',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/b6704b31969634222ef8cd3d278b9067.png'],
        likeUsers: 150,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId2',
        title: '[입고완료] 드래곤볼 반다이 S.H.Figuarts 슈퍼 손오공 무의식의 극의 토요타로 에디션 (혼웹한정)',
        content: '너무 좋습니다.',
        parentPublicId: '',
        score: 3.5,
        status: false,
        userId: 'userId2',
        itemId: 'itemId2',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/ef0f444d7e885a53b7c004bc0ac901f5.jpg'],
        likeUsers: 400,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId3',
        title: '[입고완료] 짱구는 못말려 반프레스토 SOFVIMATES 짱구2',
        content: '아주짱짱짱아주좋아요',
        parentPublicId: '',
        score: 4.7,
        status: false,
        userId: 'userId3',
        itemId: 'itemId3',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/83b10434aa797ea1eb26ca77f56b4a66.jpg'],
        likeUsers: 1205,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId4',
        title: '[입고완료] 보컬로이드 굿스마일컴퍼니 넨도로이드돌 사쿠라 미쿠 꽃구경 코디ver',
        content: '굿스마일 넨도로이드돌 사쿠라 미쿠',
        parentPublicId: '',
        score: 2.22,
        status: false,
        userId: 'userId4',
        itemId: 'itemId4',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/3933728989dd78392cccc9ea48dc0ca1.jpg'],
        likeUsers: 22,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId5',
        title: '[입고완료] 천원돌파 그렌라간 굿스마일컴퍼니 그렌 라간 (재판) (모데로이드)',
        content: '잘조립할게요',
        parentPublicId: '',
        score: 5.0,
        status: false,
        userId: 'userId5',
        itemId: 'itemId5',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/912a0d153fde1ad30b1eecdba234070e.jpg'],
        likeUsers: 120,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId6',
        title: '[24년4월입고] 짱구는 못말려 반프레스토 엉덩이 걷기 피규어 (단품선택)',
        content: '큼지막하고 귀여워요!',
        parentPublicId: '',
        score: 1.12,
        status: false,
        userId: 'userId6',
        itemId: 'itemId6',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/a5d65d19cf8f366ed0345be1c4f305bd.jpg'],
        likeUsers: 50,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId7',
        title: '[입고완료] SPY X FAMILY 세가 Luminasta 아냐 포저 외출ver',
        content: '잘 받았습니다.',
        parentPublicId: '',
        score: 4.44,
        status: false,
        userId: 'userId7',
        itemId: 'itemId7',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/0eb14de7320bc8b8a497c8a524ec47fb.jpg'],
        likeUsers: 458,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId8',
        title: '[입고완료] 5등분의 신부 후류 trio try it 피규어 나카노 미쿠 바니ver',
        content: '잘 받았습니다~',
        parentPublicId: '',
        score: 2.25,
        status: false,
        userId: 'userId8',
        itemId: 'itemId8',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/445e1b07ba061cc5aee7dd4404c75ed2.jpg'],
        likeUsers: 150,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId9',
        title: '[입고완료] 체인소맨 세가 Luminasta 체인소맨',
        content: '가성비갑 하는 만족할만 한 제품입니다',
        parentPublicId: '',
        score: 4.5,
        status: false,
        userId: 'userId9',
        itemId: 'itemId9',
        imageUrls: ['https://toyntech.wisacdn.com/_data/review/202404/08/4787e709932b854a2e24e46f53e2d1f0.jpg'],
        likeUsers: 880,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId10',
        title: '[24년4월2차입고예정] 니어 오토마타 스퀘어 에닉스 form-ism피규어 2B YoRHa No.2 Type-B (단품선택) ',
        content: '잘 받았습니다~',
        parentPublicId: '',
        score: 3.56,
        status: false,
        userId: 'userId10',
        itemId: 'itemId10',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/8559dc7e46952ef77a88437eb2e9a24a.jpg'],
        likeUsers: 300,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId11',
        title: '[입고완료] 드래곤볼 반프레스토 Blood of Saiyans 스페셜17 브로리',
        content: '믿고사는 피규어팜',
        parentPublicId: '',
        score: 4.77,
        status: false,
        userId: 'userId11',
        itemId: 'itemId11',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/6c1061ce1a5a2631ab5c8907c449e65e.jpg'],
        likeUsers: 778,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId12',
        title: '[입고완료] 나의 히어로 아카데미아 메가하우스 G.E.M. 손바닥 바쿠고 카츠키',
        content: '여윽시 바쿠고 농협은행',
        parentPublicId: '',
        score: 1.5,
        status: false,
        userId: 'userId12',
        itemId: 'itemId12',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/3cd1875085c36ec04ba14070d4bff7f6.jpg'],
        likeUsers: 150,
        purchaseOptions: [''],
    },
    {
        reviewId: 'reviewId13',
        title: '[입고완료] 봇치 더 록! 세가 DD컬렉션 야마다 료',
        content: '깔끔하고 좋습니다.',
        parentPublicId: '',
        score: 4.3,
        status: false,
        userId: 'userId13',
        itemId: 'itemId13',
        imageUrls: ['https://toyntech.wisacdn.com/_data/product/item/1530680763/9fac5c53ed4f7f2df24292a3d89b899d.jpg'],
        likeUsers: 450,
        purchaseOptions: [''],
    },
];
