// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card-img__-3XOS {
  width: 100%;
  object-fit: cover;
}

.Card_card-content__D3Sxa {
  padding: 0;
}
.Card_card-content__D3Sxa:last-child {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/card/style/Card.module.scss"],"names":[],"mappings":"AAIA;EACE,WAAA;EACA,iBAAA;AAHF;;AAMA;EACE,UAAA;AAHF;AAIE;EACE,UAAA;AAFJ","sourcesContent":[".card{\n\n}\n\n.card-img{\n  width: 100%;\n  object-fit: cover;\n}\n\n.card-content{\n  padding: 0;\n  &:last-child{\n    padding: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-img": `Card_card-img__-3XOS`,
	"card-content": `Card_card-content__D3Sxa`
};
export default ___CSS_LOADER_EXPORT___;
