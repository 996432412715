// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saveId_save-id__X6Ar5 {
  display: flex;
  align-items: center;
  margin-right: 18%;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/login/saveId/style/saveId.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;AACJ","sourcesContent":[".save-id{\n    display: flex;\n    align-items: center;\n    margin-right: 18%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"save-id": `saveId_save-id__X6Ar5`
};
export default ___CSS_LOADER_EXPORT___;
