/* eslint-disable */
import React from 'react';
import AdminComponent from '@templates/admin/Admin';

// interface AdminPageInterface{
//
// }

const AdminPage = () => {
    /*
    * 라우트 할 때 파라미터 없으면 기본 대시보드 혹은 파라미터 있을 시
    * 해당 파라미터 정보 아톰에 전달
    * */

    return <AdminComponent>loading</AdminComponent>;
};

export default AdminPage;
